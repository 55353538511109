import { TransformTag } from '@/utils'

export default function() {
  const get = path => this.$path(path, this.$store.state)

  return [
    {
      component: 'Section',
      condition: get('Page.item.customFields.contacts.length'),
      props: {
        class: 'contacts-section',
        width: 'large',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              style: 'max-width: 930px; padding-block: 3rem; padding-top: 0; ',
            },
            data: {
              content: TransformTag(get('Page.item.customFields.contact-content')),
            },
          },

          {
            component: 'Portal',
            variant: 'contact',
            data: {
              content: (get('Page.item.customFields.contacts') || []).map(contact => {
                return {
                  ...contact,
                  media: {
                    cover: [contact.avatar],
                  },
                }
              }),
            },
            props: {
              theme: 'thumbnail',
              disableNav: true,
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'large',
        style: '--section-padding-block: 0rem;',
        theme: 'primary',
        class: 'contact-lead-section',
      },
      blocks: {
        default: {
          component: 'Flex',
          props: {
            class: 'contact-lead',
          },
          blocks: {
            default: [
              {
                component: 'Image',
                props: {
                  class: 'contact-lead__image',
                },
                data: {
                  image: get('Page.item.customFields.lead.image'),
                },
              },
              {
                component: 'Lead',

                data: {
                  type: 'page',
                  reference: { onModel: 'Page', ref: get('Page.item.id') },
                  fields: ['!upsell', '!title'],
                  defaultData: {},
                  actionLabel: get('Page.item.customFields.lead.action-label') || this.$t('send'),
                },
                blocks: {
                  top: [
                    {
                      component: 'Text',
                      props: {
                        class: 'lead__form-title',
                      },
                      data: {
                        content: `<h2>${get('Page.item.customFields.lead.title') ||
                          this.$tc('getInTouch')}</h2> <span>${get(
                          'Page.item.customFields.lead.subtitle',
                        ) || ''}</span>`,
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      },
    },

    {
      component: 'Section',
      props: {
        width: 'full',
        style: '--section-padding-block: 0rem',
      },
      blocks: {
        default: [
          {
            component: 'Map',
            data: {
              ...get('Root.item.address'),
            },
          },
        ],
      },
    },
  ]
}
